import { ReviewDatapoint } from '@/components/uaas/components/ReviewBlock';
import { isEmpty, isEmptyString } from './validation';

export interface RouterQueryParams {
  [key: string]: string | number | boolean;
}
export const getRouterQuery = (path: string, values?: RouterQueryParams) => {
  if (!values) return path;
  const query = new URLSearchParams();
  for (const key in values) {
    if (values[key]) query.set(key, values[key].toString());
  }
  if (query?.toString()) return `${path}?${query}`;
  return path;
};

export const toFloatOrDefault = (value: string, defaultValue = undefined) => {
  const result = parseFloat(value);
  if (Number.isNaN(result)) return defaultValue;
  return result;
};

export const getFromStringPhoneAndPrefix = (value: string) => {
  const index = value ? value.indexOf(' ') : -1;
  const prefix = index !== -1 ? value.substring(0, index) : '';
  const phoneNumber = index !== -1 ? value.substring(index + 1) : '';
  return {
    prefix,
    phoneNumber,
  };
};

export const getModuleKeys = (
  datapoint: string | ReviewDatapoint
): string[] => {
  if (typeof datapoint === 'string') {
    return datapoint.split('.', 2);
  }
  return datapoint.datapoint.split('.', 2);
};

export const adjustNullableNumber = (value?: number): number => value ?? 0;

export const getImageDimensions = (
  src: string
): Promise<{ width: number; height: number } | null> => {
  return new Promise((resolve) => {
    if (typeof window === 'undefined') {
      resolve(null);
      return;
    }
    const img = new window.Image();
    img.src = src;
    img.onload = () => {
      resolve({ width: Math.round(img.width), height: Math.round(img.height) });
    };
  });
};

export const getValueOrDefault = (value, defaultValue) => {
  return value ?? defaultValue;
};

export const buildURLWithParams = (url: string, params: object): string => {
  if (!params) {
    return url;
  }

  const urlParams = Object.entries(params)
    .filter(([_, val]) => val)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');

  return `${url}?${urlParams}`;
};

export const valueHasDecimals = (value?: number | string) => {
  if (isNaN(Number(value))) return false;
  return Number(value) % 1 !== 0;
};

export const getSingularOrPluralInstalments = (instalments: number): string => {
  return instalments === 0 || instalments > 1 ? 'instalments' : 'instalment';
};

export const formatNumberWithDecimalsIfPresent = (value?: number) => {
  if (!value) return 0;
  return valueHasDecimals(value) ? parseFloat(value.toFixed(2)) : value;
};

export const capitalizeFirstLetter = (value: string) => {
  if (!value) return value;
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

/**
 * Converts a File object to a Base64 string.
 *
 * This function reads the provided File using a FileReader and resolves the promise
 * with the Base64 representation of the file once reading is complete.
 *
 * @param {File} file - The File object to be converted to Base64.
 * @returns {Promise<string | ArrayBuffer>} - A promise that resolves to a Base64 string
 *                                            or an ArrayBuffer representing the file data.
 * @throws {Error} - Throws an error if reading the file fails.
 */
export const fileToBase64 = (file: File): Promise<string | ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

/**
 * Converts a Base64 string back to a File object.
 *
 * This function takes a Base64 encoded string and creates a File object from it.
 * It splits the Base64 string to get the data part, decodes it, and then
 * constructs a Blob from the binary data, which is used to create the File.
 *
 * @param {string | ArrayBuffer} base64String - The Base64 encoded string to convert to a File.
 * @param {string} filename - The name to be given to the created File.
 * @param {string} mimeType - The MIME type of the file (e.g., 'image/png').
 * @returns {File} - The created File object.
 * @throws {Error} - Throws an error if window is not available, the base64String is not a string,
 *                   or if the base64String is invalid.
 */
export const base64ToFile = (
  base64String: string | ArrayBuffer,
  filename: string,
  mimeType: string
) => {
  if (!window) throw new Error('window is not available');
  if (typeof base64String !== 'string')
    throw new Error('base64String must be a string');

  const base64 = base64String.split(',')[1];
  if (!base64) throw new Error('invalid base64String');

  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i) & 0xff;
  }

  const blob = new Blob([bytes], { type: mimeType });
  return new File([blob], filename, { type: mimeType });
};

export const parseFloatOrZero = (value: string | number): number => {
  return parseFloat(
    !isEmptyString(value?.toString()) && !isEmpty(value?.toString())
      ? value?.toString()
      : '0'
  );
};

/**
 * Encodes a Uint8Array to a Base64 string.
 *
 * This function takes a Uint8Array as input and encodes it to a Base64 string.
 * It creates a binary string from the bytes and converts it to Base64 using Node.js's Buffer.
 *
 * @param {Uint8Array} buffer - The Uint8Array to encode to Base64.
 * @returns {string} - The Base64 encoded string.
 */
export const base64Encode = (buffer: Uint8Array): string => {
  let binary = '';
  buffer.forEach((byte) => {
    binary += String.fromCharCode(byte);
  });
  return Buffer.from(binary, 'binary').toString('base64');
};

/**
 * Parses a Base64 Data URL or converts an ArrayBuffer to a Base64 string.
 *
 * If the input is a valid Data URL, it extracts the Base64 portion from it.
 * If the input is an ArrayBuffer, it encodes it to a Base64 string.
 *
 * Note: This function assumes that the input string is valid UTF-8.
 * Invalid UTF-8 sequences may result in unexpected behavior.
 *
 * @param {string | ArrayBuffer | null | undefined} dataURI - A Data URL or an ArrayBuffer.
 * @returns {null | string} - The Base64 encoded string or null if no input is provided.
 * @throws {Error} - Throws an error if the input is not a valid string or ArrayBuffer.
 */
export const extractBase64 = (
  dataURI: string | ArrayBuffer | null | undefined
): null | string => {
  if (!dataURI) return null;
  if (dataURI instanceof ArrayBuffer) {
    const uint8Array = new Uint8Array(dataURI);
    return base64Encode(uint8Array);
  }
  if (typeof dataURI !== 'string') {
    throw new Error('Input must be a string or ArrayBuffer');
  }
  const base64Marker = 'base64,';
  const markerIndex = dataURI.indexOf(base64Marker);
  if (markerIndex === -1)
    throw new Error("Input string does not contain 'base64,' marker.");
  return dataURI.split(base64Marker)[1];
};

/**
 * Safely extracts the Base64 string from a Base64 Data URL or ArrayBuffer.
 *
 * This function wraps the main extraction function in a try-catch block to
 * handle any errors gracefully, providing an error message if the extraction fails.
 *
 * Note: This function assumes that the input string is valid UTF-8.
 * Invalid UTF-8 sequences may result in unexpected behavior.
 *
 * @param {string | ArrayBuffer | undefined} base64File - A Data URL or an ArrayBuffer.
 * @returns {string | null} - The Base64 encoded string or null if the extraction fails.
 * @throws {Error} - Throws an error if extraction fails.
 */
export const safeExtractBase64 = (
  base64File: string | ArrayBuffer | undefined
): string | null => {
  try {
    return extractBase64(base64File);
  } catch (e) {
    throw new Error(
      `Failed to extract base64 from schedule file: ${e.message}`
    );
  }
};

/**
 * Checks if a given date was created within the last hour.
 *
 * @param {Date} date - The date object to be checked.
 * @returns {boolean} - Returns `true` if the date was created within the last hour, otherwise `false`.
 */
export const recentlyCreated = (date: Date) => {
  const hour = 1000 * 60 * 60;
  const now = Date.now();
  return date.getTime() >= now - hour && date.getTime() <= now;
};
