import { SNACKBAR_INVESTMENT_FUNDING_METHODS } from '@/constants/snackbar-messages';
import { Snackbar } from '@/hooks/useSnackbar';
import { SnackbarPayload } from '@/store/alerts';
import {
  InvestmentFundingMethod,
  PREDEFINED_FUNDING_METHOD_ID,
} from './InvestmentFundingMethod.types';

export const combineCustomFundingMethodsWithPredefined = (
  investmentFundingMethods: InvestmentFundingMethod[],
  loanLabel: string
): InvestmentFundingMethod[] => [
  {
    id: PREDEFINED_FUNDING_METHOD_ID.GROSS,
    name: `Gross ${loanLabel} amount`,
    fees: [],
    isPredefined: true,
  },
  {
    id: PREDEFINED_FUNDING_METHOD_ID.NET,
    name: `Net ${loanLabel} amount`,
    fees: [],
    isPredefined: true,
  },
  ...investmentFundingMethods,
];

export enum FundingMethodSnackbarId {
  CANNOT_FETCH_FUNDING_METHODS = 'FETCH_INVESTMENT_FUNDING_METHODS_ERROR',
  CANNOT_FETCH_FUNDING_METHODS_FEES_SETTINGS = 'FETCH_INVESTMENT_FUNDING_METHODS_FEES_SETTINGS_ERROR',
  CREATE_SUCCESS = 'CREATE_INVESTMENT_FUNDING_METHOD_SUCCESS',
  CREATE_ERROR = 'CREATE_INVESTMENT_FUNDING_METHOD_ERROR',
  UPDATE_SUCCESS = 'UPDATE_INVESTMENT_FUNDING_METHOD_SUCCESS',
  UPDATE_ERROR = 'UPDATE_INVESTMENT_FUNDING_METHOD_ERROR',
  DELETE_SUCCESS = 'DELETE_INVESTMENT_FUNDING_METHOD_SUCCESS',
  DELETE_ERROR = 'DELETE_INVESTMENT_FUNDING_METHOD_ERROR',
}

const getInvestmentFundingMethodSnackbarPayloadById = (
  id: FundingMethodSnackbarId
): SnackbarPayload => {
  switch (id) {
    case FundingMethodSnackbarId.CANNOT_FETCH_FUNDING_METHODS:
      return {
        id,
        severity: 'error',
        title:
          SNACKBAR_INVESTMENT_FUNDING_METHODS.CANNOT_FETCH_FUNDING_METHODS_TITLE,
      };
    case FundingMethodSnackbarId.CANNOT_FETCH_FUNDING_METHODS_FEES_SETTINGS:
      return {
        id,
        severity: 'error',
        title:
          SNACKBAR_INVESTMENT_FUNDING_METHODS.CANNOT_FETCH_FUNDING_METHODS_FEES_SETTINGS_TITLE,
      };
    case FundingMethodSnackbarId.CREATE_SUCCESS:
      return {
        id,
        severity: 'success',
        title:
          SNACKBAR_INVESTMENT_FUNDING_METHODS.FUNDING_METHOD_CREATE_SUCCESS_TITLE,
      };
    case FundingMethodSnackbarId.CREATE_ERROR:
      return {
        id,
        severity: 'error',
        title:
          SNACKBAR_INVESTMENT_FUNDING_METHODS.FUNDING_METHOD_CREATE_ERROR_TITLE,
      };
    case FundingMethodSnackbarId.UPDATE_SUCCESS:
      return {
        id,
        severity: 'success',
        title:
          SNACKBAR_INVESTMENT_FUNDING_METHODS.FUNDING_METHOD_UPDATE_SUCCESS_TITLE,
      };
    case FundingMethodSnackbarId.UPDATE_ERROR:
      return {
        id,
        severity: 'error',
        title:
          SNACKBAR_INVESTMENT_FUNDING_METHODS.FUNDING_METHOD_UPDATE_ERROR_TITLE,
      };
    case FundingMethodSnackbarId.DELETE_SUCCESS:
      return {
        id,
        severity: 'success',
        title:
          SNACKBAR_INVESTMENT_FUNDING_METHODS.FUNDING_METHOD_DELETE_SUCCESS_TITLE,
      };
    case FundingMethodSnackbarId.DELETE_ERROR:
      return {
        id,
        severity: 'error',
        title:
          SNACKBAR_INVESTMENT_FUNDING_METHODS.FUNDING_METHOD_DELETE_ERROR_TITLE,
      };
    default:
      return null;
  }
};

export const displayInvestmentFundingMethodsSnackbar = (
  snackbar: Snackbar,
  id: FundingMethodSnackbarId
) => {
  const payload = getInvestmentFundingMethodSnackbarPayloadById(id);
  if (!payload) return;
  snackbar.remove(payload.id);
  snackbar.show(payload);
};

/**
 * Used to bypass some of logic related with custom funding methods implementation.
 * When feature gets fully implemented it will be removed.
 */
export const INVESTMENT_FUNDING_METHODS_FULLY_IMPLEMENTED = false;
