import { env } from '@/env/public';
import { CustomInformationResponse } from '@/features/onboarding/custom-information/utils';
import { useBranchEncodedKey } from '@/hooks/useBranchEncodedKey';
import { rootApi } from '../rootApi';
import { DirectDebitAnonymousSetupPageData } from 'kennek/interfaces/kennek';
import { GroupedLoan } from 'kennek/interfaces/loans';
import { AvailabilitySettings, Product } from 'kennek/interfaces/products';
import type {
  AddLoanToGroup,
  BorrowerDocuments,
  CreateLoan,
  DisburseTrancheMutationInput,
  LinkedLoan,
  Loan,
  LoanActivationStatus,
  LoanProductRules,
  Payment,
  PaymentGroupSchedule,
  PaymentSchedule,
  PayoffPayload,
  RestructureLoan,
  Tranche,
  TrancheUpdate,
  TransactionChannel,
  UpdateActiveInstallment,
  UpdateLoan,
} from '@/interfaces/loans';
import type {
  ApprovedLoan,
  ApprovedLoansQuery,
  DirectDebitCompleteAnonymousSetupPayload,
  GetLoans,
  Group,
  LoanGroup,
  LoanSummary,
  LoanTrancheSummary,
  LoansQuery,
  OnboardingLoanData,
  PaginatedResponse,
  RepaymentsQuery,
} from '@/interfaces/loans/queries';

export const loansBaseApi = '/api/loans';

interface ModulrDataForLoan {
  modulrAccountNumber: string;
  modulrSortCode: string;
  currency: string;
  status: string;
}

export const loansApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    createLoan: builder.mutation({
      query: (loanDetails: Partial<CreateLoan>) => ({
        url: loansBaseApi,
        method: 'POST',
        body: loanDetails,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Loans', 'Scheduling']),
    }),
    updateLoan: builder.mutation({
      query: (loanDetails: Partial<UpdateLoan>) => ({
        url: `${loansBaseApi}/${loanDetails.id}`,
        method: 'PUT',
        body: loanDetails,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Loans', 'Scheduling']),
    }),
    createLoanGroup: builder.mutation<Group, { groupName: string }>({
      query: ({ groupName }) => ({
        url: `${loansBaseApi}/groups`,
        method: 'POST',
        body: { name: groupName },
      }),
      invalidatesTags: ['Loan-Groups'],
    }),
    deleteLoanGroup: builder.mutation<void, { groupId: string }>({
      query: ({ groupId }) => ({
        url: `${loansBaseApi}/groups/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Loan-Groups', 'Loans'],
    }),
    addLoanToGroup: builder.mutation<Group, AddLoanToGroup>({
      query: ({ groupId, loanId }) => ({
        url: `${loansBaseApi}/groups/${groupId}/loans`,
        method: 'POST',
        body: { loanId },
      }),
      invalidatesTags: ['Loan-Groups', 'Loans'],
    }),
    getOnboardingLoans: builder.query<GetLoans, LoansQuery>({
      query: (params) => ({
        url: loansBaseApi + '/onboarding',
        params,
      }),
      providesTags: ['Loans'],
      keepUnusedDataFor:
        env.NEXT_PUBLIC_RTK_QUERY_KEEP_UNUSED_DATA_FOR_ONBOARDING_LOANS,
    }),
    getOnboardingLoan: builder.query<OnboardingLoanData, { id: string }>({
      query: ({ id }) => ({
        url: loansBaseApi + `/onboarding/${id}`,
      }),
      providesTags: ['Loans'],
    }),
    getApprovedLoans: builder.query<
      PaginatedResponse<ApprovedLoan[]>,
      ApprovedLoansQuery
    >({
      query: (query) => ({
        url: loansBaseApi + '/approved',
        params: removeNullUndefinedProps(query),
      }),
      providesTags: ['Loans'],
      keepUnusedDataFor:
        env.NEXT_PUBLIC_RTK_QUERY_KEEP_UNUSED_DATA_FOR_APPROVED_LOANS,
    }),
    fetchApprovedLoans: builder.mutation<
      PaginatedResponse<ApprovedLoan[]>,
      ApprovedLoansQuery
    >({
      query: (query) => ({
        url: loansBaseApi + '/approved',
        params: removeNullUndefinedProps(query),
      }),
    }),
    getApprovedLoansByBorrower: builder.query<
      GroupedLoan[],
      ApprovedLoansQuery
    >({
      query: (params) => ({
        url: loansBaseApi + '/approved/grouped-by-borrower',
        params: removeNullUndefinedProps(params),
      }),
      providesTags: ['Loans'],
    }),
    deleteUploadedFile: builder.mutation({
      query: (id: BorrowerDocuments['_id']) => ({
        url: `${loansBaseApi}/documents/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Loans'],
    }),
    getLoan: builder.query<Loan, Loan['id']>({
      query: (id) => ({
        url: `${loansBaseApi}/${id}`,
      }),
      providesTags: ['Loans'],
    }),
    getLoanProductRules: builder.query<
      LoanProductRules,
      { id: Product['id']; action?: 'CREATE' | 'RESTRUCTURE' }
    >({
      query: ({ id, action = 'CREATE' }) => ({
        url: loansBaseApi + `/product/${id}/rules`,
        method: 'GET',
        params: { action },
      }),
      providesTags: ['Loans'],
    }),
    reconcilePayment: builder.mutation({
      query: (paymentDetails: Payment) => ({
        url: loansBaseApi + '/payment',
        method: 'POST',
        body: paymentDetails,
      }),
      invalidatesTags: ['Loans'],
    }),
    getUploadedFiles: builder.query<BorrowerDocuments[], string>({
      query: (email) => ({
        url: loansBaseApi + `/documents/user-documents/${email}`,
        method: 'GET',
      }),
      providesTags: ['Loans'],
    }),
    getLoanDocuments: builder.query<
      BorrowerDocuments[],
      {
        loanEncodedKey?: string;
        email?: string;
        loanId?: string;
        draft?: boolean;
      }
    >({
      query: ({ loanEncodedKey, email, loanId, draft = false }) => ({
        url: loansBaseApi + '/documents/loan-documents',
        method: 'GET',
        params: {
          loanEncodedKey,
          email,
          loanId,
          draft,
        },
      }),
      providesTags: ['Loans'],
    }),
    activateLoan: builder.mutation({
      query: ({
        loanEncodedKey,
        notifyUsers,
      }: {
        loanEncodedKey: string;
        notifyUsers?: Array<string>;
      }) => ({
        url: `${loansBaseApi}/${loanEncodedKey}/activate`,
        method: 'POST',
        body: {
          notifyUsers,
        },
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Loans']),
    }),
    getRepaymentSchedule: builder.query<PaymentSchedule, RepaymentsQuery>({
      query: ({ loanId, fromDate, dateTo }) => ({
        url: loansBaseApi + `/${loanId}/payment-schedule`,
        params: {
          fromDate,
          dateTo,
          consolidate: env.NEXT_PUBLIC_CONSOLIDATE,
          attachPredictedCashflow: true,
        },
      }),
      providesTags: ['Loans', 'RepaymentSchedule'],
    }),
    getTransactionChannel: builder.query<TransactionChannel[], void>({
      query: () => ({
        url: loansBaseApi + '/transaction-channels',
      }),
      providesTags: ['Loans'],
    }),
    loanPayoff: builder.mutation({
      query: (body: PayoffPayload) => ({
        url: loansBaseApi + '/payment/payoff',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Loans'],
    }),
    getLoanSummary: builder.query<LoanSummary, { loanId: string }>({
      query: ({ loanId }) => ({
        url: loansBaseApi + '/' + loanId + '/summary',
        params: {
          mapSummaryBalance: true,
        },
      }),
      providesTags: ['Loans'],
    }),
    getLoanSummaryNoCache: builder.query<LoanSummary, { loanId: string }>({
      query: ({ loanId }) => ({
        url: loansBaseApi + '/' + loanId + '/summary',
        params: {
          mapSummaryBalance: true,
        },
      }),
      providesTags: ['Loans'],
      keepUnusedDataFor: 0.0001,
    }),
    restructureLoan: builder.mutation<
      { id: string },
      RestructureLoan & { loanId: string; useDocumentManagement: boolean }
    >({
      query: ({ loanId, ...restructureInfo }) => ({
        url: loansBaseApi + `/${loanId}/restructure`,
        method: 'POST',
        body: restructureInfo,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Loans']),
    }),
    getLoanPreview: builder.mutation<
      { id: string },
      RestructureLoan & { loanId: string; useDocumentManagement: boolean }
    >({
      query: ({ loanId, ...restructureInfo }) => ({
        url: loansBaseApi + `/${loanId}/restructure/preview`,
        method: 'POST',
        body: restructureInfo,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Loans']),
    }),
    disburseTranche: builder.mutation({
      query: ({
        loanId,
        body,
      }: {
        loanId: string;
        body: DisburseTrancheMutationInput;
      }) => ({
        url: loansBaseApi + `/${loanId}/disburse`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Loans']),
    }),
    getLoanTrancheSummary: builder.query<LoanTrancheSummary, string>({
      query: (id) => ({
        url: loansBaseApi + `/${id}/tranches/summary`,
        method: 'GET',
      }),
      providesTags: ['Loans'],
    }),
    getLoanTranches: builder.query<Tranche[], string>({
      query: (id) => ({
        url: loansBaseApi + `/${id}/tranches`,
        method: 'GET',
      }),
      providesTags: ['Loans'],
    }),
    updateLoanTranches: builder.mutation({
      query: (data: { tranches: TrancheUpdate[]; loanId: string }) => ({
        url: `${loansBaseApi}/${data.loanId}/tranches`,
        method: 'PUT',
        body: { tranches: data.tranches },
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Loans', 'Scheduling']),
    }),
    getModulrDataForLoan: builder.mutation<
      ModulrDataForLoan,
      { loanEncodedKey: string }
    >({
      query: ({ loanEncodedKey }) => ({
        url: `${loansBaseApi}/modulr-account/loan/${loanEncodedKey}`,
        method: 'GET',
      }),
    }),
    getLinkedLoans: builder.query<LinkedLoan[], string>({
      query: (loanEncodedKey) => ({
        url: loansBaseApi + `/linked-loans/${loanEncodedKey}`,
        method: 'GET',
      }),
      providesTags: ['Loans'],
    }),
    getLoanActivationStatus: builder.query<LoanActivationStatus, string>({
      query: (id) => ({
        url: loansBaseApi + `/${id}/can-activate`,
        method: 'GET',
      }),
      providesTags: ['Loans'],
    }),
    getLoanGroup: builder.query<LoanGroup, string>({
      query: (id) => ({
        url: loansBaseApi + `/groups/${id}/details`,
        method: 'GET',
      }),
      providesTags: ['Loans'],
    }),
    getLoanGroupSchedule: builder.query<PaymentGroupSchedule, string>({
      query: (id) => ({
        url: loansBaseApi + `/groups/${id}/schedule`,
        method: 'GET',
      }),
    }),
    updateActiveRepaymentScheduleInstallments: builder.mutation({
      query: ({
        loanId,
        payload,
      }: {
        loanId: string;
        payload: UpdateActiveInstallment;
      }) => ({
        url: loansBaseApi + `/${loanId}/active-payment-schedule`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['RepaymentSchedule']),
    }),
    resendActiveLoanInvitations: builder.mutation({
      query: ({ emails, loanId }: { emails: string[]; loanId: string }) => ({
        url: loansBaseApi + `/${loanId}/resend-invitations`,
        method: 'POST',
        body: { emails },
      }),
      extraOptions: {
        noAuth: true,
      },
    }),
    getDirectDebitPageData: builder.query<
      DirectDebitAnonymousSetupPageData,
      { accessToken: string }
    >({
      query: ({ accessToken }) => ({
        url: loansBaseApi + '/direct-debit/anonymous-setup/page-data',
        params: {
          accessToken,
        },
      }),
    }),
    completeDirectDebitSetup: builder.mutation<
      void,
      {
        accessToken: string;
        paymentMethod: DirectDebitCompleteAnonymousSetupPayload;
      }
    >({
      query: ({ accessToken, paymentMethod }) => ({
        url: loansBaseApi + '/direct-debit/anonymous-setup/complete',
        method: 'POST',
        params: {
          accessToken,
        },
        body: paymentMethod,
      }),
    }),
    getCustomInformations: builder.query<CustomInformationResponse, string>({
      query: (loanId: string) => ({
        url: loansBaseApi + `/${loanId}/loan-onboarding-custom-information/`,
        method: 'GET',
      }),
    }),

    updateCustomInformation: builder.mutation<
      CustomInformationResponse,
      { loanId: string; payload: CustomInformationResponse }
    >({
      query: ({ loanId, payload }) => ({
        url: loansBaseApi + `/${loanId}/loan-onboarding-custom-information`,
        method: 'PUT',
        body: payload,
      }),
    }),
    validateRepaymentSchedule: builder.mutation({
      query: (body: FormData) => ({
        url: loansBaseApi + '/validate-repayment-schedule-import',
        method: 'POST',
        body,
        formData: true,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Loans']),
    }),
    uploadRepaymentSchedule: builder.mutation({
      query: (body: FormData) => ({
        url: loansBaseApi + '/repayment-schedule-import',
        method: 'POST',
        body,
        formData: true,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Loans']),
    }),
  }),
});

export const {
  useCreateLoanMutation,
  useUpdateLoanMutation,
  useCreateLoanGroupMutation,
  useAddLoanToGroupMutation,
  useGetOnboardingLoansQuery,
  useGetOnboardingLoanQuery,
  useLazyGetOnboardingLoansQuery,
  useGetApprovedLoansQuery,
  useFetchApprovedLoansMutation,
  useGetApprovedLoansByBorrowerQuery,
  useActivateLoanMutation,
  useLazyGetLoanQuery,
  useGetLoanQuery,
  useGetLoanProductRulesQuery,
  useReconcilePaymentMutation,
  useLazyGetUploadedFilesQuery,
  useDeleteUploadedFileMutation,
  useGetUploadedFilesQuery,
  useGetLoanDocumentsQuery,
  useGetRepaymentScheduleQuery,
  useGetLoanSummaryQuery,
  useGetLoanSummaryNoCacheQuery,
  useGetTransactionChannelQuery,
  useLoanPayoffMutation,
  useRestructureLoanMutation,
  useGetLoanPreviewMutation,
  useDisburseTrancheMutation,
  useGetLinkedLoansQuery,
  useGetLoanTrancheSummaryQuery,
  useGetLoanTranchesQuery,
  useUpdateLoanTranchesMutation,
  useGetModulrDataForLoanMutation,
  useGetLoanGroupQuery,
  useGetLoanGroupScheduleQuery,
  useGetLoanActivationStatusQuery,
  useUpdateActiveRepaymentScheduleInstallmentsMutation,
  useResendActiveLoanInvitationsMutation,
  useGetDirectDebitPageDataQuery,
  useCompleteDirectDebitSetupMutation,
  useGetCustomInformationsQuery,
  useUpdateCustomInformationMutation,
  useValidateRepaymentScheduleMutation,
  useUploadRepaymentScheduleMutation,
} = loansApi;

export const { useQuerySubscription: useGetLoanQuerySubscription } =
  loansApi.endpoints.getLoan;

export const { useQuerySubscription: useGetLoanDocumentsQuerySubscription } =
  loansApi.endpoints.getLoanDocuments;

export const {
  useQuerySubscription: useGetRepaymentScheduleQuerySubscription,
} = loansApi.endpoints.getRepaymentSchedule;

export const useGuardedGetLoanProductRulesQuery = (
  productTypeKey?: string,
  availabilitySettings?: AvailabilitySettings
) => {
  const { branchEncodedKey } = useBranchEncodedKey();
  const branchSettings = availabilitySettings?.branchSettings;
  const userHasAccess =
    branchSettings?.availableProductBranches.includes(branchEncodedKey) ||
    branchSettings?.forAllBranches;

  return useGetLoanProductRulesQuery(
    {
      id: productTypeKey,
    },
    {
      skip: !productTypeKey || !userHasAccess,
    }
  );
};

const removeNullUndefinedProps = (obj: Record<string, any>) => {
  const clonedObject = structuredClone(obj);
  Object.keys(clonedObject).forEach((key) => {
    if (clonedObject[key] === null || clonedObject[key] === undefined) {
      delete clonedObject[key];
    }
  });
  return clonedObject;
};
