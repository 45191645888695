import { InvestmentFundingMethodFeeSettings } from '@/features/onboarding/investments/funding-methods/InvestmentFundingMethod.types';
import { rootApi } from '../rootApi';
import { loansBaseApi } from './index';
import {
  InvestmentFundingMethod,
  UpsertInvestmentFundingMethodDto,
} from '@/interfaces/loans/queries';

const investmentFundingMethodsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getInvestmentFundingMethods: builder.query<
      InvestmentFundingMethod[],
      { branchEncodedKey: string; loanIdOrEncodedKey?: string }
    >({
      query: (params) => ({
        url: `${loansBaseApi}/investment/funding-methods`,
        params: {
          branchEncodedKey: params.branchEncodedKey,
          loanIdOrEncodedKey: params.loanIdOrEncodedKey,
        },
      }),
      providesTags: ['Investment-Funding-Methods'],
    }),
    getInvestmentFundingMethodsFeesSettings: builder.query<
      InvestmentFundingMethodFeeSettings[],
      { branchEncodedKey: string }
    >({
      query: (params) => ({
        url: `${loansBaseApi}/investment/funding-methods/fees-settings`,
        method: 'GET',
        params: { branchEncodedKey: params.branchEncodedKey },
      }),
      providesTags: ['Investment-Funding-Methods-Fees-Settings'],
    }),
    createInvestmentFundingMethod: builder.mutation<
      InvestmentFundingMethod,
      { branchEncodedKey: string; dto: UpsertInvestmentFundingMethodDto }
    >({
      query: (params) => ({
        url: `${loansBaseApi}/investment/funding-methods`,
        method: 'POST',
        params: { branchEncodedKey: params.branchEncodedKey },
        body: params.dto,
      }),
      invalidatesTags: ['Investment-Funding-Methods'],
    }),
    updateInvestmentFundingMethod: builder.mutation<
      InvestmentFundingMethod,
      {
        investmentFundingMethodId: string;
        branchEncodedKey: string;
        dto: UpsertInvestmentFundingMethodDto;
      }
    >({
      query: (params) => ({
        url: `${loansBaseApi}/investment/funding-methods/${params.investmentFundingMethodId}`,
        method: 'PUT',
        params: { branchEncodedKey: params.branchEncodedKey },
        body: params.dto,
      }),
      invalidatesTags: ['Investment-Funding-Methods'],
    }),
    deleteInvestmentFundingMethod: builder.mutation<
      void,
      {
        investmentFundingMethodId: string;
        branchEncodedKey: string;
      }
    >({
      query: (params) => ({
        url: `${loansBaseApi}/investment/funding-methods/${params.investmentFundingMethodId}`,
        method: 'DELETE',
        params: { branchEncodedKey: params.branchEncodedKey },
      }),
      invalidatesTags: ['Investment-Funding-Methods'],
    }),
  }),
});

export const {
  useGetInvestmentFundingMethodsQuery,
  useGetInvestmentFundingMethodsFeesSettingsQuery,
  useCreateInvestmentFundingMethodMutation,
  useUpdateInvestmentFundingMethodMutation,
  useDeleteInvestmentFundingMethodMutation,
} = investmentFundingMethodsApi;
