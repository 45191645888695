import { useCallback, useMemo, useRef, useState } from 'react';
import { Button, ErrorRefresher, Surface, Title } from 'ui';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { INVESTOR_MESSAGE } from '@/constants/messages';
import {
  InvestmentsFormFields,
  InvestmentsFormRef,
} from '@/features/investments/InvestmentsForm';
import { InvestmentsForm } from '@/features/investments/InvestmentsForm/InvestmentsForm';
import { useInvestmentTypeSettingState } from '@/features/investments/InvestmentsForm/InvestmentsForm.utils';
import AmountToFundTypeSelect from '@/features/onboarding/investments/AmountToFundTypeForm';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useSnackbar } from '@/hooks/useSnackbar';
import { usePatchOnboardingTaskStatusMutation } from '@/services/accounts/borrower';
import { useGetLoanProductRulesQuery, useGetLoanQuery } from '@/services/loans';
import {
  useAddInvestmentsMutation,
  useGetInvestmentsQuery,
} from '@/services/loans/investment';
import {
  useCreateInvestmentFundingMethodMutation,
  useDeleteInvestmentFundingMethodMutation,
  useGetInvestmentFundingMethodsFeesSettingsQuery,
  useGetInvestmentFundingMethodsQuery,
  useUpdateInvestmentFundingMethodMutation,
} from '@/services/loans/investment-funding-methods';
import { isFetchBaseQueryError } from '@/services/utils';
import { sum } from '@/utils/math';
import { useConfirmDialog } from '../../../hooks/useConfirmDialog';
import { useInvestmentsSummary } from '../../investments/hooks/useInvestmentsSummary';
import { saveInvestmentsPayloadFactory } from '../../investments/saveInvestmentsPayloadFactory';
import { loanHasFees } from '../utils';
import AmountToFundDetails from './AmountToFundDetails';
import { InvestmentsSettingsForm } from './InvestmentsSettingsForm';
import {
  DeleteInvestmentDialog,
  DeleteInvestmentDialogOptions,
} from './dialogs/DeleteInvestmentDialog';
import {
  SkipInvestmentsWarningDialog,
  SkipInvestmentsWarningDialogOptions,
} from './dialogs/SkipInvestmentsWarningDialog';
import {
  InvestmentFundingMethod,
  InvestmentFundingMethodCreateFormData,
  InvestmentFundingMethodEditFormData,
} from './funding-methods/InvestmentFundingMethod.types';
import {
  FundingMethodSnackbarId,
  INVESTMENT_FUNDING_METHODS_FULLY_IMPLEMENTED,
  combineCustomFundingMethodsWithPredefined,
  displayInvestmentFundingMethodsSnackbar,
} from './funding-methods/InvestmentFundingMethod.utils';
import { InvestmentFundingMethodCreateFormDialog } from './funding-methods/InvestmentFundingMethodCreateFormDialog';
import { InvestmentFundingMethodDeleteConfirmationDialog } from './funding-methods/InvestmentFundingMethodDeleteConfirmationDialog';
import { InvestmentFundingMethodEditFormDialog } from './funding-methods/InvestmentFundingMethodEditFromDialog';
import { InvestmentFundingMethodListDialog } from './funding-methods/InvestmentFundingMethodListDialog';
import { useDeleteInvestment } from './hooks/useDeleteInvestment';
import { useOriginatorInvestorOptions } from './hooks/useOriginatorInvestorOptions';
import { useOriginatorLenderOfRecordsOptions } from './hooks/useOriginatorLenderOfRecordsOptions';
import { investmentsFormFieldsFactory } from './utils/investmentFormFieldsFactory';
import { Fees } from '@/interfaces/loans/fee-transaction';
import { AmountToFundType, type Loan } from '@/interfaces/loans/index';

type InvestmentsProps = {
  onContinue: () => void;
  onBack: () => void;
  loanId: Loan['id'];
  isReview: boolean;
};

const InvestmentsContainer = ({
  onContinue,
  onBack,
  loanId,
  isReview,
}: InvestmentsProps) => {
  const snackbar = useSnackbar();
  const labelsConfig = useGetLabelsConfig();

  const [patchOnboardingTaskStatus, { isLoading: isPatchingTaskStatus }] =
    usePatchOnboardingTaskStatusMutation();

  const { data: loanDetails, ...loanQuery } = useGetLoanQuery(loanId, {
    skip: !loanId,
  });

  const { investorOptions, isLoading: isLoadingOriginatorInvestors } =
    useOriginatorInvestorOptions(loanDetails?.assignedBranchKey);

  const { lenderOfRecordOptions, isLoading: isLoadingLenderOfRecord } =
    useOriginatorLenderOfRecordsOptions(loanDetails?.assignedBranchKey);

  const { data: loanProductRules, ...rulesQuery } = useGetLoanProductRulesQuery(
    { id: loanDetails?.productTypeKey },
    { skip: !loanDetails?.productTypeKey }
  );

  const { data: originalInvestments = [], isLoading: isLoadingInvestments } =
    useGetInvestmentsQuery(loanDetails?.encodedKey, {
      skip: !loanDetails?.encodedKey,
    });

  const initialFormInvestments = useMemo<InvestmentsFormFields[]>(
    (): InvestmentsFormFields[] =>
      investmentsFormFieldsFactory(originalInvestments),
    [originalInvestments]
  );

  const { investmentTypeSettingEnabled, setInvestmentTypeSettingEnabled } =
    useInvestmentTypeSettingState(originalInvestments);

  const [saveInvestments, { isLoading: isSubmitting }] =
    useAddInvestmentsMutation();

  const [fixedInterestRate, setFixedInterestRate] = useState<number | null>(
    null
  );

  const [isInvestmentsFormValid, setIsInvestmentsFormValid] = useState(true);
  const [amountToFundType, setAmountToFundType] = useState<AmountToFundType>(
    loanHasFees(loanDetails) ? null : AmountToFundType.GROSS
  );
  const investmentFormRef = useRef<InvestmentsFormRef>();

  const {
    total: amountToAllocate,
    capitalizedFees,
    deductedFees,
    originalLoanAmount,
  } = useInvestmentsSummary(loanDetails, loanProductRules, amountToFundType);

  const {
    isOpen: isDeleteDialogOpen,
    closeDialog: closeDeleteDialog,
    dialogOptions: deleteDialogOptions,
    openDialog: openDeleteDialog,
  } = useConfirmDialog<DeleteInvestmentDialogOptions>();
  const {
    isOpen: isSkipInvestmentsWarningDialogOpen,
    closeDialog: closeSkipInvestmentsWarningDialog,
    dialogOptions: skipInvestmentsWarningDialogOptions,
    openDialog: openSkipInvestmentsWarningDialog,
  } = useConfirmDialog<SkipInvestmentsWarningDialogOptions>();

  const submit = async () => {
    const form = investmentFormRef.current;

    if (!form) return;

    const formInvestments = await form.submit();

    const investmentsAmount =
      formInvestments?.reduce(
        (acc, { amount }) => acc + parseFloat(amount),
        0
      ) ?? 0;

    if (!formInvestments?.length || investmentsAmount < amountToAllocate) {
      const skipInvestmentsDialogActionResult =
        await openSkipInvestmentsWarningDialog({
          type:
            investmentsAmount > 0 && investmentsAmount < amountToAllocate
              ? 'partialAllocation'
              : 'skip',
          currency: loanProductRules?.currency,
          nonAllocatedAmount: amountToAllocate - investmentsAmount,
        });
      if (!skipInvestmentsDialogActionResult.confirmed) {
        // if skip action declined, stop execution, otherwise continue
        return;
      }
    }

    const payload = saveInvestmentsPayloadFactory({
      originatorEncodedKey: loanDetails.assignedBranchKey,
      loanEncodedKey: loanDetails.encodedKey,
      investments: formInvestments,
      amountToFundType,
      investmentTypeSettingEnabled,
    });

    try {
      await saveInvestments(payload);
    } catch (error) {
      if (isFetchBaseQueryError(error) && error.status === 406) {
        snackbar.show({
          severity: 'error',
          title: INVESTOR_MESSAGE.SAVE_INVESTOR_FAILED,
          content: INVESTOR_MESSAGE.SAVE_LOAN_DO_NOT_SUPPORT(labelsConfig),
        });
      } else {
        snackbar.show({
          severity: 'error',
          title: INVESTOR_MESSAGE.SAVE_INVESTOR_FAILED,
        });
      }
    }

    await handleContinue();
  };

  const handleContinue = async () => {
    await patchOnboardingTaskStatus({
      taskName: 'INVESTOR',
      taskStatus: 'COMPLETED',
      loanId,
    });
    onContinue();
  };

  const { deleteInvestment, isDeleting: isInvestmentDeleting } =
    useDeleteInvestment();

  // returns true if action completed successfully
  const onInvestmentDelete = useCallback(
    async (investmentField: InvestmentsFormFields, investmentId?: string) => {
      if (!investmentId) return true;
      const investor = investorOptions.find(
        (investor) => investor.value === investmentField.investorId
      );
      const removeAction = await openDeleteDialog({
        investorName: investor.label,
      });
      if (removeAction.confirmed) {
        try {
          await deleteInvestment(investmentId);
          return true;
        } catch (_error) {
          return false;
        }
      }
      return false;
    },
    [investorOptions, openDeleteDialog, deleteInvestment]
  );

  const [
    investmentFundingMethodListDialogOpen,
    setInvestmentFundingMethodListDialogOpen,
  ] = useState(false);

  const [investmentFundingMethodToDelete, setInvestmentFundingMethodToDelete] =
    useState<InvestmentFundingMethod>(null);

  const [
    investmentFundingMethodCreateFormDialogOpen,
    setInvestmentFundingMethodCreateFormDialogOpen,
  ] = useState(false);

  const [investmentFundingMethodToEdit, setInvestmentFundingMethodToEdit] =
    useState<InvestmentFundingMethod>(null);

  const getInvestmentFundingMethodsQuery = useGetInvestmentFundingMethodsQuery(
    { branchEncodedKey: loanDetails?.assignedBranchKey },
    { skip: !loanDetails }
  );

  if (getInvestmentFundingMethodsQuery.isError) {
    displayInvestmentFundingMethodsSnackbar(
      snackbar,
      FundingMethodSnackbarId.CANNOT_FETCH_FUNDING_METHODS
    );
  }

  const investmentFundingMethods = combineCustomFundingMethodsWithPredefined(
    getInvestmentFundingMethodsQuery.data ?? [],
    labelsConfig.loanUpper
  );

  const getInvestmentFundingMethodsFeesSettingsQuery =
    useGetInvestmentFundingMethodsFeesSettingsQuery(
      { branchEncodedKey: loanDetails?.assignedBranchKey },
      { skip: !loanDetails }
    );

  if (getInvestmentFundingMethodsFeesSettingsQuery.isError) {
    displayInvestmentFundingMethodsSnackbar(
      snackbar,
      FundingMethodSnackbarId.CANNOT_FETCH_FUNDING_METHODS_FEES_SETTINGS
    );
  }

  const [createInvestmentFundingMethod, createInvestmentFundingMethodMutation] =
    useCreateInvestmentFundingMethodMutation();

  const [updateInvestmentFundingMethod, updateInvestmentFundingMethodMutation] =
    useUpdateInvestmentFundingMethodMutation();

  const [deleteInvestmentFundingMethod, deleteInvestmentFundingMethodMutation] =
    useDeleteInvestmentFundingMethodMutation();

  const investmentFundingMethodsFeesSettings =
    getInvestmentFundingMethodsFeesSettingsQuery.data ?? [];

  const handleCreateInvestmentFundingMethodFormSubmit = async (
    data: InvestmentFundingMethodCreateFormData
  ) => {
    try {
      await createInvestmentFundingMethod({
        branchEncodedKey: loanDetails.assignedBranchKey,
        dto: data,
      }).unwrap();
      displayInvestmentFundingMethodsSnackbar(
        snackbar,
        FundingMethodSnackbarId.CREATE_SUCCESS
      );
      setInvestmentFundingMethodCreateFormDialogOpen(false);
      setInvestmentFundingMethodListDialogOpen(true);
    } catch (_) {
      displayInvestmentFundingMethodsSnackbar(
        snackbar,
        FundingMethodSnackbarId.CREATE_ERROR
      );
    }
  };

  const handleUpdateInvestmentFundingMethodFormSubmit = async (
    data: InvestmentFundingMethodEditFormData
  ) => {
    try {
      await updateInvestmentFundingMethod({
        investmentFundingMethodId: data.id,
        branchEncodedKey: loanDetails.assignedBranchKey,
        dto: data,
      }).unwrap();
      displayInvestmentFundingMethodsSnackbar(
        snackbar,
        FundingMethodSnackbarId.UPDATE_SUCCESS
      );
      setInvestmentFundingMethodToEdit(null);
      setInvestmentFundingMethodListDialogOpen(true);
    } catch (_) {
      displayInvestmentFundingMethodsSnackbar(
        snackbar,
        FundingMethodSnackbarId.UPDATE_ERROR
      );
    }
  };

  const handleDeleteInvestmentFundingMethodFormSubmit = async () => {
    if (!investmentFundingMethodToDelete) return;

    try {
      await deleteInvestmentFundingMethod({
        investmentFundingMethodId: investmentFundingMethodToDelete.id,
        branchEncodedKey: loanDetails.assignedBranchKey,
      }).unwrap();
      setInvestmentFundingMethodToDelete(null);
      displayInvestmentFundingMethodsSnackbar(
        snackbar,
        FundingMethodSnackbarId.DELETE_SUCCESS
      );
    } catch (_) {
      displayInvestmentFundingMethodsSnackbar(
        snackbar,
        FundingMethodSnackbarId.DELETE_ERROR
      );
    }
  };

  if (
    INVESTMENT_FUNDING_METHODS_FULLY_IMPLEMENTED &&
    (getInvestmentFundingMethodsFeesSettingsQuery.isError ||
      getInvestmentFundingMethodsQuery.isError)
  ) {
    return (
      <ErrorRefresher
        onRefresh={() => {
          getInvestmentFundingMethodsFeesSettingsQuery.refetch();
          getInvestmentFundingMethodsQuery.refetch();
        }}
      />
    );
  }

  const isLoading =
    loanQuery.isLoading ||
    rulesQuery.isLoading ||
    isPatchingTaskStatus ||
    isLoadingOriginatorInvestors ||
    isLoadingLenderOfRecord ||
    isLoadingInvestments;

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <LoadingAnimationPlat fitBox />
      </div>
    );
  }

  return (
    <>
      <InvestmentFundingMethodCreateFormDialog
        feesSettings={investmentFundingMethodsFeesSettings}
        isOpen={investmentFundingMethodCreateFormDialogOpen}
        onBack={() => {
          setInvestmentFundingMethodCreateFormDialogOpen(false);
          setInvestmentFundingMethodListDialogOpen(true);
        }}
        onClose={() => {
          setInvestmentFundingMethodCreateFormDialogOpen(false);
          setInvestmentFundingMethodListDialogOpen(true);
        }}
        onSubmit={(data) => {
          handleCreateInvestmentFundingMethodFormSubmit(data);
        }}
        isLoading={getInvestmentFundingMethodsFeesSettingsQuery.isLoading}
        isSubmitting={createInvestmentFundingMethodMutation.isLoading}
      />
      <InvestmentFundingMethodEditFormDialog
        fundingMethod={investmentFundingMethodToEdit}
        isOpen={!!investmentFundingMethodToEdit}
        onBack={() => {
          setInvestmentFundingMethodToEdit(null);
          setInvestmentFundingMethodListDialogOpen(true);
        }}
        onClose={() => {
          setInvestmentFundingMethodToEdit(null);
          setInvestmentFundingMethodListDialogOpen(false);
        }}
        onSubmit={(data) => {
          handleUpdateInvestmentFundingMethodFormSubmit(data);
        }}
        isSubmitting={updateInvestmentFundingMethodMutation.isLoading}
      />
      <InvestmentFundingMethodListDialog
        fundingMethods={investmentFundingMethods}
        isOpen={investmentFundingMethodListDialogOpen}
        onAddNewClicked={() => {
          setInvestmentFundingMethodListDialogOpen(false);
          setInvestmentFundingMethodCreateFormDialogOpen(true);
        }}
        onClose={() => setInvestmentFundingMethodListDialogOpen(false)}
        onDeleteClicked={(fundingMethod) =>
          setInvestmentFundingMethodToDelete(fundingMethod)
        }
        onEditClicked={(fundingMethod) => {
          setInvestmentFundingMethodListDialogOpen(false);
          setInvestmentFundingMethodToEdit(fundingMethod);
        }}
        onSelectClicked={() => setInvestmentFundingMethodListDialogOpen(false)}
        isLoading={getInvestmentFundingMethodsQuery.isFetching}
      />
      <InvestmentFundingMethodDeleteConfirmationDialog
        onConfirm={() => handleDeleteInvestmentFundingMethodFormSubmit()}
        fundingMethodToDelete={investmentFundingMethodToDelete}
        onIsOpenChange={() => setInvestmentFundingMethodToDelete(null)}
        isLoading={deleteInvestmentFundingMethodMutation.isLoading}
      />
      <div>
        <div className="flex justify-between grow my-5">
          <AmountToFundTypeSelect
            value={amountToFundType}
            onChange={setAmountToFundType}
            isReadOnly={!loanHasFees(loanDetails)}
            onEditClicked={() => setInvestmentFundingMethodListDialogOpen(true)}
          />

          <AmountToFundDetails
            amountToFundType={amountToFundType}
            originalLoanAmount={originalLoanAmount}
            capitalizedFees={sum(capitalizedFees, (fee: Fees) => fee.amount)}
            deductedFees={sum(deductedFees, (fee: Fees) => fee.amount)}
            currency={loanProductRules?.currency}
            total={amountToAllocate}
            amountToAllocate={amountToAllocate}
          />
        </div>

        <Surface border="dark" padding="sm">
          <div className="flex flex-col">
            <div className="pt-2">
              <Title title="Investor list" titleSize="sm" insideCard />
            </div>

            <div className="border-neutral-300 border-t h-1 mb-5 mt-4" />

            <InvestmentsSettingsForm
              className="mb-4"
              advancedSettingsInitiallyExpanded={false}
              fixedInterestRate={{
                disabled: isLoading,
                initialFixedInterestRate: loanDetails?.interestSettings?.rate,
                onFixedInterestRateChange: setFixedInterestRate,
              }}
              investmentType={{
                enabled: investmentTypeSettingEnabled,
                onInvestmentTypeEnabledChange: setInvestmentTypeSettingEnabled,
              }}
            />

            <InvestmentsForm
              ref={investmentFormRef}
              currency={loanProductRules?.currency}
              disabled={isLoading}
              investments={initialFormInvestments}
              investors={investorOptions}
              lenderOfRecords={lenderOfRecordOptions}
              fixedInterestRate={fixedInterestRate}
              maxInvestmentsAmount={amountToAllocate}
              originatorEncodedKey={loanDetails?.assignedBranchKey}
              onFormStateChange={(formState) =>
                setIsInvestmentsFormValid(formState === 'Valid')
              }
              showInvestmentTypeField={investmentTypeSettingEnabled}
              onBeforeInvestmentDelete={onInvestmentDelete}
            />
          </div>
        </Surface>

        <div className="flex justify-between">
          <Button
            layout="ghost"
            className="mt-6"
            onClick={onBack}
            disabled={isSubmitting || isLoading || isInvestmentDeleting}
          >
            {isReview ? 'Cancel' : 'Back'}
          </Button>

          <Button
            className="self-end mt-6"
            loading={isSubmitting || isInvestmentDeleting}
            disabled={
              loanQuery.isLoading ||
              isLoadingOriginatorInvestors ||
              isLoadingLenderOfRecord ||
              isLoadingInvestments ||
              !isInvestmentsFormValid ||
              isSubmitting ||
              isInvestmentDeleting ||
              !amountToFundType
            }
            onClick={submit}
          >
            {isReview ? 'Save' : 'Save and continue'}
          </Button>
        </div>
      </div>

      <DeleteInvestmentDialog
        isOpen={isDeleteDialogOpen}
        closeDialog={closeDeleteDialog}
        dialogOptions={deleteDialogOptions}
      />

      <SkipInvestmentsWarningDialog
        isOpen={isSkipInvestmentsWarningDialogOpen}
        closeDialog={closeSkipInvestmentsWarningDialog}
        dialogOptions={skipInvestmentsWarningDialogOptions}
      />
    </>
  );
};

export { InvestmentsContainer as Investments };
